import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: () => import('@/views/MainView.vue'),
    children: [
      {
        path: '',
        name: 'main.home',
        component: () => import('@/components/pages/home-page/HomePage.vue'),
      },
      {
        path: '/registration',
        name: 'main.registration',
        component: () => import('@/components/pages/register-page/RegisterPage.vue'),
      },
      {
        path: '/login',
        name: 'main.login',
        component: () => import('@/components/pages/login-page/LoginPage.vue'),
      },
      {
        path: '/loginviewer',
        name: 'main.loginviewer',
        component: () => import('@/components/pages/login-admin-page/LoginAdminPage.vue'),
      },
      {
        path: '/profile',
        name: 'main.profile',
        component: () => import('@/components/pages/user-page/UserPage.vue'),
      },
      {
        path: '/submissions',
        name: 'main.submissions',
        component: () => import('@/components/pages/submissions-page/SubmissionsPage.vue'),
      },
      // {
      //   path: '/upfront-commission',
      //   name: 'main.upfront-commission',
      //   component: () => import('@/components/pages/upfront-commission-page/UpfrontCommissionPage.vue'),
      // },
      // {
      //   path: '/back-commission',
      //   name: 'main.back-commission',
      //   component: () => import('@/components/pages/back-commission-page/BackCommissionPage.vue'),
      // },
      {
        path: '/commissions',
        name: 'main.commissions',
        component: () => import('@/components/pages/commissions-page/CommissionsPage.vue'),
      },
      {
        path: '/funded',
        name: 'main.funded',
        component: () => import('@/components/pages/funded-page/FundedPage.vue'),
      },
      {
        path: '/account/:id',
        name: 'main.account',
        component: () => import('@/components/pages/account-page/AccountPage.vue'),
      },
      {
        path: '/*',
        name: 'main.error',
        component: () => import('@/components/pages/404-page/404.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
