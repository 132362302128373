<template>
  <div id="app">
    <loader
      v-if="loading"
      object="#5A02B8"
      color1="#000"
      color2="#5A02B8"
      size="10"
      speed="2"
      bg="#000"
      objectbg="#fff"
      opacity="80"
      disableScrolling="true"
      name="spinning"
    ></loader>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.loading = false
      } else this.loading = true
    }
  },
}
</script>
